<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('MYADDRESSES.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('MYADDRESSES.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editMyAddressCard" class="vs-con-loading__container">
          <h3 v-if="!newAddr">{{$t('MYADDRESSES.EDIT.HEADER')}}</h3>
          <h3 v-if="newAddr">{{$t('MYADDRESSES.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('MYADDRESSES.EDIT.LABEL.BEZ')"  class="inputx" :placeholder="$t('MYADDRESSES.EDIT.LABEL.BEZ')"  v-model="selected.bez" />
            <vs-input :label="$t('MYADDRESSES.EDIT.LABEL.STREET')"  class="inputx ml-3" :placeholder="$t('MYADDRESSES.EDIT.LABEL.STREET')"  v-model="selected.street1" />
            <vs-input :label="$t('MYADDRESSES.EDIT.LABEL.STREET2')"  class="inputx ml-3" :placeholder="$t('MYADDRESSES.EDIT.LABEL.STREET2')"  v-model="selected.street2" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-input :label="$t('MYADDRESSES.EDIT.LABEL.PLZ')"  class="inputx" :placeholder="$t('MYADDRESSES.EDIT.LABEL.PLZ')"  v-model="selected.plz" />
            <vs-input :label="$t('MYADDRESSES.EDIT.LABEL.ORT')"  class="inputx ml-3" :placeholder="$t('MYADDRESSES.EDIT.LABEL.ORT')"  v-model="selected.city" />
            <vs-select :label="$t('MYADDRESSES.EDIT.LABEL.LAND')" v-model="selected.country_fk" class=" ml-3">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
            </vs-select>
            <vs-input :label="$t('MYADDRESSES.EDIT.LABEL.ANMERKUNG')"  class="ml-3 inputx" :placeholder="$t('MYADDRESSES.EDIT.LABEL.ANMERKUNG')"  v-model="selected.notes" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
                <vs-radio v-model="selected.addressType" :disabled="!newAddr" vs-name="ownertype" :vs-value="GetOwnerTypeUser()" class="mr-3">{{$t('MYADDRESSES.EDIT.RADIO.USER')}}</vs-radio>
                <vs-radio v-model="selected.addressType" :disabled="!newAddr" vs-name="ownertype" :vs-value="GetOwnerTypeCompany()" class="mr-3">{{$t('MYADDRESSES.EDIT.RADIO.COMPANY')}}</vs-radio>
          </div>  
          <SaveCancel class="mt-3" :SaveAllowed = !addressDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddMyAddress">{{$t('MYADDRESSES.ADD')}}</vs-button>
            </div> 
            <div id="myaddress_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="addresses"
                :noDataText="$t('MYADDRESSES.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('MYADDRESSES.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">
                  <vs-th sort-key="addressType">
                    
                  </vs-th>              
                  <vs-th sort-key="bez">
                    {{$t('MYADDRESSES.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="street1">
                    {{$t('MYADDRESSES.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="street2">
                    {{$t('MYADDRESSES.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="plz">
                    {{$t('MYADDRESSES.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="city">
                    {{$t('MYADDRESSES.TABLE.COL5')}}
                  </vs-th>    
                  <vs-th sort-key="country">
                    {{$t('MYADDRESSES.TABLE.COL6')}}
                  </vs-th>   
                  <vs-th sort-key="notes">
                    {{$t('MYADDRESSES.TABLE.COL7')}}
                  </vs-th> 
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" > 
                    <vs-td>
                      <vs-avatar :icon="OwnerTypeToIconName(tr.addressType)"/>
                    </vs-td>                     
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>      
                    <vs-td :data="tr.street1">
                      {{tr.street1}}
                    </vs-td>
                    <vs-td :data="tr.street2">
                      {{tr.street2}}
                    </vs-td>
                    <vs-td :data="tr.plz">
                      {{tr.plz}}
                    </vs-td>
                    <vs-td :data="tr.city">
                      {{tr.city}}
                    </vs-td>
                    <vs-td :data="tr.country_fk">
                      {{GetNameOfCountry(tr.country_fk)}}
                    </vs-td>  
                    <vs-td :data="tr.notes">
                      {{tr.notes}}
                    </vs-td> 
                    <vs-td>
                      <vs-button  @click="EditMyAddress(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteAddr(tr.id,tr.bez)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';
import {ownertype} from "../helper/staticFuncHelper";

export default {
  name: "MyAddresses",
  components: {
    SaveCancel
  },
    data: function (){
      return {
      addresses:[],
      selected:[],
      selectedOld:"",
      editMode:false,
      newAddr:false
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.address.addressListUser.data != null)
      {
        data = this.$store.state.address.addressListUser.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.address.addressListUser.status) != 'undefined')
        loading = this.$store.state.address.addressListUser.status.loading;
      return loading;
    },
    addressDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    },
    countries(){
      return this.GetListOfCountriesForSelect();
    }

  },
  watch: {
      getTableData(value) {
          this.addresses = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#myaddress_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#myaddress_table'});
        }
      }
    },
  methods: {    
      ...helperMethods,
      LoadData: function ()
      {
          this.$store.dispatch('address/getAddressListForCompanyAndUser', {id:0,forTable:true}); 
      },
      GetOwnerTypeUser(){
        return ownertype.USER;
      },
      GetOwnerTypeCompany(){
        return ownertype.COMPANY;
      }, 
      EditMyAddress(id){
        this.newAddr = false;
        if(this.$store.state.address.addressListUser.data != null)
        {
          for(var i = 0; i < this.$store.state.address.addressListUser.data.length; i++)
          {
            if(this.$store.state.address.addressListUser.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.address.addressListUser.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      AddMyAddress(){
        this.selected={id:0,street1:"",street2:"",plz:"",country_fk:1,city:"",notes:"",id_owner:0,addressType:ownertype.USER};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
        this.newAddr = true;
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editMyAddressCard'});

        var name = this.selected.bez;
            this.$store.dispatch('address/saveAddr', this.selected)
              .then(resp => {
                if(resp.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MYADDRESSES.SUCCESS.SAVE').replace("*1*",name)});
                  this.editMode = false;
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MYADDRESSES.ERROR.SAVE').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MYADDRESSES.ERROR.SAVE').replace("*1*",name)+' '+error});
              });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newAddr = false;
      },
      OpenConfirmDeleteAddr(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('MYADDRESSES.QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('address/deleteAddr', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MYADDRESSES.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('MYADDRESSES.WARNING.DELETE').replace("*1*",parameters.bez)}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MYADDRESSES.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>